

<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-radio-group v-model="raceMode" row>
          Race mode: &nbsp;
          <v-radio
            label="RaceAsYouGo"
            value="RaceAsYouGo"
            :disabled="raceState !== RaceStopped"
          ></v-radio>
          <v-radio
            label="Versus"
            value="Versus"
            :disabled="raceState !== RaceStopped"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-slider
          :disabled="raceState !== RaceStopped"
          v-model="numLaps"
          :label="'Laps per race: ' + numLaps"
          min="1"
          max="10"
          thumb-label
          @change="updateNumLaps()"
        ></v-slider>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          v-if="
            raceState == RaceStopped &&
            !displayFinishMessage &&
            raceMode == 'RaceAsYouGo'
          "
          color="primary"
          :loading="startPending"
          :disabled="startPending"
          @click="startRace()"
          >Start race!</v-btn
        >
        <v-btn
          v-if="
            raceState == RaceStopped &&
            !displayFinishMessage &&
            raceMode == 'Versus'
          "
          color="primary"
          :loading="startPending"
          :disabled="startPending"
          @click="startRace()"
          >All cars and players are ready ➡ Start race!</v-btn
        >
        <v-container
          v-if="raceState == RaceAboutToStart"
          style="margin-top: 1em"
        >
          <h3>🔴🔴🔴⚫⚫</h3>
          <h2>The race is about to start</h2>
          <p>The countdown should appear soon on the finish line</p>
        </v-container>
        <v-btn
          v-if="raceState == RaceOngoing"
          color="primary"
          :loading="stopPending"
          :disabled="stopPending"
          @click="stopRace()"
          >stop race</v-btn
        >
        <v-container v-if="raceState == RaceOngoing" style="margin-top: 1em">
          <h3>🟢🟢🟢🟢🟢</h3>
          <h2>The race is ongoing</h2>
          <p>You can see the laps times below</p>
        </v-container>
        <v-container v-if="displayFinishMessage" style="margin-top: 1em">
          <h3>🏁🏁🏁🏁🏁</h3>
          <h2>The race is finished for all players</h2>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="cars"></v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Connected Cars</h3>
        <direction-offset
          v-for="i in connectedCars.length"
          :key="i - 1"
          :carId="connectedCars[i - 1]"
          :directionOffset="connectedCarsDirectionOffset[i - 1]"
        ></direction-offset>
        <h3>Disconnected Cars</h3>
        <li
          v-for="car of disconnectedCars"
          :key="car"
          style="padding-top: 16px; padding-bottom: 16px"
        >
          id: {{ car }}
        </li>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          style="display: block"
          v-for="i of connectedCars"
          :key="i"
          @click="simulateLap(i)"
          >Simulate a lap for car {{ i }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
.v-label.theme--light {
  color: rgba(0, 0, 0) !important;
}
.v-card {
  margin-top: 1em;
}
</style>


<script>
import gql from "graphql-tag";
import DirectionOffset from "./DirectionOffset.vue";
export default {
  components: { DirectionOffset },
  name: "LiveRace",
  data: () => ({
    statusRefreshTimer: null,

    raceMode: "RaceAsYouGo",

    RaceStopped: 0,
    RaceOngoing: 1,
    RaceAboutToStart: 3,

    raceState: 0,
    raceStartTimestamp: 0,
    numLaps: 3,

    startPending: false,
    stopPending: false,
    displayFinishMessage: false,

    connectedCars: [],
    disconnectedCars: [],
    connectedCarsDirectionOffset: [],
    

    headers: [],
    cars: [
      {
        id: "1",
        player: "alice",
        lapTimes: [],
      },
      {
        id: "2",
        player: "bob",
        lapTimes: [],
      },
    ],
  }),
  mounted() {
    console.log(`mounted`);
    this.updateNumLaps();
    this.updateRaceStatus();
  },
  methods: {
    async startRace() {
      //this.raceState = this.RaceAboutToStart; // updated by race status
      this.startPending = true;
      setTimeout(() => (this.startPending = false), 15000);
      const result = await this.$apolloClient.mutate({
        mutation: gql`
          mutation StartRace($raceStart: RaceStartInput) {
            startRace(raceStart: $raceStart)
          }
        `,
        variables: {
          raceStart: {
            raceMode: this.raceMode,
            numLaps: this.numLaps,
          },
        },
      });
      console.log(`result start ${result}`);
      this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 1000);
    },
    async stopRace() {
      this.stopPending = true;
      setTimeout(() => (this.stopPending = false), 15000);
      const result = await this.$apolloClient.mutate({
        mutation: gql`
          mutation {
            stopRace
          }
        `,
      });
      console.log(`race stop result ${result}`);
    },
    msToString(timestampMs) {
      const minutes = Math.floor(timestampMs / 1000 / 60);
      const remainingTimeMs = timestampMs - minutes * 60 * 1000;
      const seconds = Math.floor(remainingTimeMs / 1000);
      const ms = remainingTimeMs - seconds * 1000;
      return `${minutes}:${seconds}.${ms}`;
    },
    updateNumLaps() {
      this.headers = [
        { text: "Car", value: "id" },
        { text: "Player", value: "player" },
        { text: "Ranking", value: "ranking" },
      ];
      for (let lapId = 1; lapId <= this.numLaps; lapId++) {
        this.headers.push({ text: `Lap ${lapId}`, value: `lap${lapId}` });
      }
      this.headers.push({ text: "Total", value: "total" });
    },

    async updateRaceStatus() {
      // Call to the graphql mutation
      const result = await this.$apolloClient.query({
        // Query
        query: gql`
          query {
            getRaceStatus {
              raceMode
              raceState
              startTimestamp {
                timestamp
                ms
              }
              numLaps
              associatedCars {
                carId
                playerId
                lapsDurationMs
                ranking
              }
              connectedCarsId
              connectedCarsDirectionOffset
              disconnectedCarsId
            }
          }
        `,
      });
      console.log(`race status:\n ${JSON.stringify(result)}`);

      this.connectedCars = result.data.getRaceStatus.connectedCarsId;
      this.connectedCarsDirectionOffset =
        result.data.getRaceStatus.connectedCarsDirectionOffset;
      this.disconnectedCars = result.data.getRaceStatus.disconnectedCarsId;

      const raceState = result.data.getRaceStatus.raceState;
      console.log(`race state:\n ${JSON.stringify(raceState)}`);

      if (raceState == "Ongoing") {
        this.raceState = this.RaceOngoing;
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      } else if (
        raceState == "Stopped" &&
        this.raceState !== this.RaceStopped
      ) {
        this.raceState = this.RaceStopped;
        this.displayFinishMessage = true;
        setTimeout(() => {
          this.displayFinishMessage = false;
          this.updateRaceStatus();
        }, 5000);
        return;
      } else if (raceState == "AboutToStart") {
        this.raceState = this.RaceAboutToStart;
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      } else {
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      }

      if (
        this.raceState == this.RaceOngoing ||
        this.raceState == this.RaceAboutToStart
      ) {
        this.raceMode = result.data.getRaceStatus.raceMode;
      }

      const cars = result.data.getRaceStatus.associatedCars;
      let updatedCars = [];
      cars.forEach((car) => {
        const updatedCar = {
          id: car.carId,
          player: car.playerId,
        };
        if (this.raceState == this.RaceOngoing || this.displayFinishMessage) {
          let sumTimeMs = 0;
          for (let lapId = 1; lapId <= car.lapsDurationMs.length; lapId++) {
            updatedCar[`lap${lapId}`] = this.msToString(
              car.lapsDurationMs[lapId - 1]
            );
            sumTimeMs += car.lapsDurationMs[lapId - 1];
          }
          updatedCar[`ranking`] = car.ranking;
          updatedCar[`total`] = this.msToString(sumTimeMs);
        }
        updatedCars.push(updatedCar);
      });
      this.cars = updatedCars;
      console.log(this.cars);
    },
    async simulateLap(carId) {
      const now = Date.now();
      await this.$apolloClient.mutate({
        mutation: gql`
          mutation AddLineDetection($detection: LineDetection) {
            addLineDetection(detection: $detection)
          }
        `,
        variables: {
          detection: {
            detectionTimestamp: {
              timestamp: Math.floor(now / 1000),
              ms: now % 1000,
            },
            carId: carId,
          },
        },
      });
      console.log(`lap simulated`);
    },
  },
};
</script>
